
import Vue, { PropType } from "vue";

import { Building, Room } from "@/interfaces";

export default Vue.extend({
  components: {},
  props: {
    building: {
      type: Object as PropType<Building>,
      required: true,
    },
  },
  computed: {
    rooms(): Room[] {
      if (this.building.rooms) {
        return this.building.rooms;
      }

      return [];
    },
  },
});
